<template>
  <v-container class="fill-height">
    <v-row align="center">
      <v-col align-self="center" class="d-flex justify-center">
        <v-card elevation="10" class="mx-auto" width="500" max-width="500">
          <v-app-bar color="blue" flat dark>
            <v-toolbar-title>
              <v-icon>mdi-file-find</v-icon>
              Find
            </v-toolbar-title>
          </v-app-bar>
          <v-container>
            <v-alert type="error" v-if="errorMessage != ''">
              {{ errorMessage }}
            </v-alert>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                v-model="queueId"
                :rules="queueIdRules"
                maxlength="10"
                label="Queue ID"
                prefix="Q-"
                required
              >
              </v-text-field>

              <v-btn
                color="primary"
                class="mr-4 mt-5"
                @click="findQueue"
                :loading="loading"
                x-large
              >
                Search
              </v-btn>
            </v-form>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      valid: false,
      queueId: "",
      queueIdRules: [v => !!v || "this field is required."],
      errorMessage: ""
    };
  },
  methods: {
    findQueue() {
      if (this.$refs.form.validate()) {
        this.$router.push({
          name: "QueueResult",
          query: { id: this.queueId }
        });
      }
    }
  }
};
</script>

<style></style>
